// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://mandate-api.uitoux.in/api',
  sourceurl: "https://mandate-api.uitoux.in/",
  frontUrl: "https://mandate.uitoux.in",
  copyRight: '© 2021-2023 Copyright CAMSPay',
  termsLink: 'https://camspay.com/company/terms-and-conditions',
  policyLink: 'https://camspay.com/company/privacy-policy',
  v2Key: "6LccX_IpAAAAAPTCgONrO4MLoS0jHoIs9n1LkYoq",
  v3Key: "6LcxXvIpAAAAACa0m01BWyQ2T6k5NKrihl2ZSAux",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
