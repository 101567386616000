import { Component } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { Router, NavigationStart, NavigationEnd, Event  } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  title = 'CAMSPay';
  loading: boolean = false;
  options: AnimationOptions = {
    path: '/assets/gif/page-loader.json',
  };

  constructor(private router: Router) {
    this.router.events.subscribe((routerEvent: Event) => {
      if(routerEvent instanceof NavigationStart) {
        this.loading = true;
      }
      if(routerEvent instanceof NavigationEnd) {
        this.loading = false;
        window.scrollTo(0, 0);
      }
    });
  }

  ngOnInit(): void {
    window.addEventListener("dragover",function(e:any){
      e = e || event;
      e.dataTransfer.dropEffect = "none";
      e.preventDefault();
    },false);
    window.addEventListener("drop",function(e:any){
      e = e || event;
      e.preventDefault();
    },false);
    
  }
}
