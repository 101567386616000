
import { throwError as observableThrowError, throwError, timeout } from 'rxjs';

import { Injectable } from '@angular/core';
// import { Http} from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { TokenStorageService } from './token-storage.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { IndexeddbService } from './indexeddb.service';
import dayjs from 'dayjs/esm';
import { DateTime } from 'luxon';
// import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AppHttpService {
  public hostUrl = environment.baseUrl;
  public hostcount = 0;
  // public apiHost = environment.GOOGLE_URL;

  constructor(
    public TokenStorageService: TokenStorageService, public httpClient: HttpClient,
    public router: Router, private indexeddbService: IndexeddbService
  ) { }

  getHeader(headerOptions: any, qparams: any = null, doNotSendAuthorizationParam: any) {
    let headerParams: any = {};
    if (doNotSendAuthorizationParam !== true && this.TokenStorageService.getToken()) {
      //send authorization param
      headerParams['x-auth-token'] = this.TokenStorageService.getToken();
      headerParams['Authorization'] = 'Bearer ' + this.TokenStorageService.getToken();
      headerParams['X-CSRF-TOKEN'] = this.TokenStorageService.getToken();

      //console.log('TimeZoneOffset', {'TimeZoneOffset': new Date().getTimezoneOffset() + ''});
      //console.log('BrowserTime', dayjs().format('YYYY-MM-DD HH:mm:ss.SSS').toString());
      //console.log('BrowserZone', this.getBrowserTimeZone());
    }
    //Declaring Timezone
    let sepHeader = {
      'TimeZoneOffset': new Date().getTimezoneOffset() + '',
      'BrowserTime': dayjs().format('YYYY-MM-DD HH:mm:ss.SSS').toString(),
      'BrowserZone': this.getBrowserTimeZone()
    }
    //Adding Custom Header Params
    if (headerOptions) {
      if(headerOptions?.contentType) {
        headerParams['Content-Type'] = headerOptions.contentType;
      }
      headerParams = { ...headerParams, ...sepHeader, ...headerOptions }
    } else {
      headerParams = { ...headerParams, ...sepHeader }
    }

    let params: HttpParams = new HttpParams();
    for (let key in qparams) {
      params.set(key, qparams[key]);
    }
    //console.log('Header PArams => ', headerParams);
    let headers = new HttpHeaders(headerParams);
    return headers;
  }

  getBrowserTimeZone(): any {
    const timeZoneOffsetMinutes = new Date().getTimezoneOffset();
    const hours = Math.abs(Math.floor(timeZoneOffsetMinutes / 60));
    const minutes = Math.abs(timeZoneOffsetMinutes % 60);
    const sign = timeZoneOffsetMinutes < 0 ? '+' : '-';
    const formattedOffset = `${sign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    return formattedOffset;
  }

  /* getBrowserTimeZone(): any {
    // Get the browser's time zone
    const timeZone = DateTime.local().zoneName;
    return timeZone;
  } */

  get(url: any, apiHost: any = null, params: any = null, headerOptions: any = null, doNotSendAuthorizationParam: boolean = false) {
    let httpOptions = this.getHeader(headerOptions, params, doNotSendAuthorizationParam);
    if (apiHost == null) {
      apiHost = this.hostUrl;
    }
    return this.httpClient.get<any>(apiHost + url, { params: params, headers: httpOptions }).pipe(map(data => {
      if (data) {
        return data
      } else {
        return []
      }
    }), tap(), catchError(this.handleError))
  }

  post(url: any, params: any = null, apiHost: any = null, responseType: any = null, headerOptions: any = null, timeoutValue: number = 600000, doNotSendAuthorizationParam: boolean = false) {
    //this.loaderService.show();
    let httpOptions = this.getHeader(headerOptions, params, doNotSendAuthorizationParam);
    // console.log(headerOptions)
    if (apiHost == null && apiHost != '') {
      apiHost = this.hostUrl;
    }
    return this.httpClient.post<any>(apiHost + url, params, { headers: httpOptions, ...responseType }).pipe(map(data => {
      if (data) {
        return data
      } else {
        return []
      }
    }), tap(), catchError(err => this.handleError(err)))
  }

  postProgress(url: any, params: any = null, apiHost: any = null, responseType: any = null, headerOptions: any = null, timeoutValue: number = 180000, doNotSendAuthorizationParam: boolean = false) {
    //this.loaderService.show();
    let httpOptions = this.getHeader(headerOptions, params, doNotSendAuthorizationParam);
    // console.log(headerOptions)
    if (apiHost == null && apiHost != '') {
      apiHost = this.hostUrl;
    }
    return this.httpClient.post<any>(apiHost + url, params, { headers: httpOptions, reportProgress: true, observe: 'events', ...responseType }).pipe(tap(), catchError(err => this.handleError(err)))
  }

  put(url: any, params: any = null, headerOptions: any = null, doNotSendAuthorizationParam: boolean = false) {
    let httpOptions = this.getHeader(headerOptions, params, doNotSendAuthorizationParam);
    return this.httpClient.put<any>(this.hostUrl + url, params, { headers: httpOptions }).pipe(map(data => {
      if (data) {
          return data
      } else {
          return []
      }
    }), tap(), catchError(this.handleError))
  }

  delete(url: any, params: any = null, apiHost: any = null, headerOptions: any = null, doNotSendAuthorizationParam: boolean = false) {
    if (apiHost == null) {
        apiHost = this.hostUrl;
    }
    let httpOptions = this.getHeader(headerOptions, params, doNotSendAuthorizationParam);
    return this.httpClient.delete<any>(apiHost + url, { headers: httpOptions, body: params }).pipe(map(data => {
      if (data) {
        return data
      } else {
        return []
      }
    }), tap(), catchError(this.handleError))
  }

  public handleError(err: HttpErrorResponse) {
    console.log('HTTP Error => ', err);
    // return an observable with a user-facing error message
    if (err.status == 403 || err.status == 401) {
      localStorage.clear();
      this.clearFilesFromIndexedDB();
      location.replace('auth/login?session=expired');
      //this.router.navigate(['/login']);
    }
    return throwError(err.error);
  };

  clearFilesFromIndexedDB() {
    this.indexeddbService.clearAllFiles().subscribe(() => {
      console.log('All files cleared from IndexedDB.');
    });
    this.indexeddbService.clearAllMandate().subscribe(() => {
      console.log('All Mandate cleared from IndexedDB.');
    });
    this.indexeddbService.clearAllTempFiles().subscribe(() => {
      console.log('All Temp files cleared from IndexedDB.');
    });
    this.indexeddbService.clearAllMultiFiles().subscribe(() => {
      console.log('All Multiple files cleared from IndexedDB.');
    });
  }

}
