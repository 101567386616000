import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppHttpService } from 'src/app/shared/services/app-http.service';
import * as decode from 'jsonwebtoken';
let baseUrl = environment.baseUrl;
@Injectable({
    providedIn: "root"
})
export class AuthService {
    constructor(private http: AppHttpService) {
    }
    //Login
    loginUser(user:any) {
        return this.http.post('/Token/create-token', user);
    }
    //Forgot Password
    forgotPassword(data: any) {
        return this.http.post('/Users/forgot-password', data);
    }
    //Reset Password
    resetPassword(data:any) {
        return this.http.put('/Users/reset-password', data)
    }

    getFullToken() {
        return localStorage.getItem("api_token");
    }
    //Get Token
    getToken() {
        return !!localStorage.getItem("api_token");
    } 
    // Verify Captcha V2
    verifyCaptchaV2 (token:string) {
        return this.http.post('/Users/verify-v2', token, null, null, {"Content-Type": "application/json"});
    }
    // Verify Captcha V3
    verifyCaptchaV3 (token:string) {
        return this.http.post('/Users/verify-v3', token, null, null, {"Content-Type": "application/json"});
    }


    /*****************  Sample APIs *****************/
    sendOtp(data:any) {
        return this.http.post('/sendotp', data);
    }
    otpVerify(data:any) {
        return this.http.post('/vendorverifypassword', data);
    }
    verifyPassword(data:any, token:any) {
        return this.http.post('/user/vendorresetpassword', data, null, { 'Authorization' : 'Bearer ' + token  });
    }
    
       
}
