<!-- <div *ngIf="loading" class="page-loader-wrap">
    <div class="page-loader-content">
        <div class="page-loader-item">
            <ng-lottie [options]="options"></ng-lottie>
        </div>
    </div>
</div> -->

<router-outlet></router-outlet>
<ngx-ui-loader bgsColor="#4E4AD8" fgsColor="#4E4AD8" pbColor="#4E4AD8" [pbThickness]="4" overlayColor="rgba(255,255,255,.8)"></ngx-ui-loader>
