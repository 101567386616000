import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule } from "ngx-ui-loader";
import { LottieModule } from 'ngx-lottie';
import { ToastrModule } from 'ngx-toastr';
import { NgxIndexedDBModule, DBConfig } from 'ngx-indexed-db';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import player from 'lottie-web';

const dbConfig: DBConfig = {
  name: 'myDB',
  version: 8,
  objectStoresMeta: [
    {
      store: 'files',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [{ name: 'fileName', keypath: 'fileName', options: { unique: false } }],
    },
    {
      store: 'mandate',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [{ name:'mandateId', keypath:'mandateId', options: { unique: true } }],
    },
    {
      store: 'tempFiles',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [{ name: 'fileName', keypath: 'fileName', options: { unique: false } }],
    },
    {
      store: 'multiFiles',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [{ name: 'file', keypath: 'file', options: { unique: false } }],
    },
  ],
};

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxUiLoaderModule,
    NgxUiLoaderRouterModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    LottieModule.forRoot({ player: playerFactory }),
    NgxIndexedDBModule.forRoot(dbConfig)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
