import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable, forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IndexeddbService {

  constructor(private dbService: NgxIndexedDBService) { }

  /******** With Reference Number File ************/
  addFile(fileName: string, filePath: string, referenceNumber: string, mandateDocumentId: any, mandateId: any) {
    const fileData = { fileName, filePath, referenceNumber, mandateDocumentId, mandateId };
    return this.dbService.add('files', fileData);
  }
  //Add Multiple Files
  addFiles(files: { fileName: string; filePath: string; referenceNumber: string, mandateDocumentId: any, mandateId: any }[]): Observable<any> {
    const addFileObservables: Observable<any>[] = files.map((file) => {
      return this.addFile(file.fileName, file.filePath, file.referenceNumber, file.mandateDocumentId, file.mandateId);
    });
    return forkJoin(addFileObservables);
  }
  //Get All Files
  getAllFiles(): Observable<any[]> {
    return this.dbService.getAll('files');
  }
  //Delete Single File
  deleteFileById(id: number): Observable<any> {
    return this.dbService.delete('files', id);
  }
  //Clear All Files
  clearAllFiles(): Observable<any> {
    return this.dbService.clear('files');
  }

  /************* Mandate Batch ID ***********/
  addMandate(mandateId: string, batchId: string): Observable<any> {
    const mandateData = { mandateId, batchId };
    return this.dbService.add('mandate', mandateData);
  }

  //getMandate
  getMandateAll(): Observable<any> {
    return this.dbService.getAll('mandate');
  }

  getMandateById(id: any): Observable<any> {
    return this.dbService.getByKey('mandate', id);
  }

  //clearMandate
  clearAllMandate(): Observable<any> {
    return this.dbService.clear('mandate');
  }

  /************* Without Reference Number Temp File ***********/
  addTempFile(fileName: string, jpgBytes: string, mandateDocumentId: any, mandateId: any) {
    const fileData = { fileName, jpgBytes, mandateDocumentId, mandateId };
    return this.dbService.add('tempFiles', fileData);
  }
  addTempFiles(files: { fileName: string; jpgBytes: string, mandateDocumentId: any, mandateId: any }[]): Observable<any> {
    const addTempFileObservables: Observable<any>[] = files.map((file) => {
      return this.addTempFile(file.fileName, file.jpgBytes, file.mandateDocumentId, file.mandateId);
    });
    return forkJoin(addTempFileObservables);
  }
  getAllTempFiles(): Observable<any[]> {
    return this.dbService.getAll('tempFiles');
  }
  deleteTempFileById(id: number): Observable<any> {
    return this.dbService.delete('tempFiles', id);
  }
  clearAllTempFiles(): Observable<any> {
    return this.dbService.clear('tempFiles');
  }

  /************* Multiple Files Store ***********/
  addMultiFile(file: Blob): Observable<any> {
    const fileData = { file };
    return this.dbService.add('multiFiles', fileData);
  }
  addMultiFiles(files: Blob[]): Observable<any> {
    const addFileObservables: Observable<any>[] = files.map((file) => {
      return this.addMultiFile(file);
    });
    return forkJoin(addFileObservables);
  }
  getAllMultiFiles(): Observable<any[]> {
    return this.dbService.getAll('multiFiles');
  }
  deleteMultiFileById(id: number): Observable<any> {
    return this.dbService.delete('multiFiles', id);
  }
  clearAllMultiFiles(): Observable<any> {
    return this.dbService.clear('multiFiles');
  }
}
